export {};
// export const route = '/liquidity';

// const LiquidityRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('../Liquidity')),
//   name: 'Liquidity',
// };

// export default LiquidityRoute;
