export {};
// export const route = '/launchpad';

// const LiquidityRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('../LaunchPad')),
//   name: 'LaunchPad',
// };

// export default LiquidityRoute;
