import React from 'react';
import { Wrapper } from './Header.styled';
import withWallet from 'hoc/withWallet';
import { Container } from 'components/Container/Container';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MenuForm from './Menu';
import { onMoveAnimation } from 'services/hooks/useDevelopUI';
export const HeaderComponent = ({
  account,
  chainId,
  isConnectedCompatibleChain,
  onOpenConnectModal,
}: any) => {
  const history = useHistory();
  const [scrollPosition, setSrollPosition] = React.useState<any>(0);
  const handleScroll = (e: any) => {
    const headerAp = document.querySelector('#custom-header-ov') as HTMLElement;
    if (scrollPosition > e.currentTarget.scrollY) {
      if (headerAp) {
        headerAp.style.opacity = '1';
      }
    } else if (scrollPosition < e.currentTarget.scrollY) {
      if (headerAp) {
        headerAp.style.opacity = '0';
      }
    }
    setSrollPosition(e.currentTarget.scrollY);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e), {
      passive: true,
    });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPosition]);
  return (
    <Wrapper id={`custom-header-ov`}>
      <MenuForm />
      <Container>
        <div className="header">
          <div className="left-header">
            <div
              className="logo"
              onClick={() => {
                history.push('/');
              }}
            >
              <img
                src={require('assets/logo/galoswap logo-02.svg').default}
                alt=""
              />
            </div>
            <div className="nav-lists">
              <div
                className="nav-nav"
                onClick={() => {
                  history.push('/');
                }}
              >
                Home
              </div>
              <div
                className="nav-nav"
                onClick={() => {
                  window.open('https://app.galoswap.io/', 'blank');
                }}
              >
                Swap
              </div>
              <div
                className="nav-nav"
                onClick={() => {
                  window.open('https://app.galoswap.io/liquidity', 'blank');
                }}
              >
                Liquidity
              </div>
              <div
                className="nav-nav"
                onClick={() => {
                  window.open('https://app.galoswap.io/leaderboard', 'blank');
                }}
              >
                Leaderboard
              </div>
            </div>
          </div>

          {!account && (
            <div
              className="btn-launch-gt"
              onClick={() => {
                onOpenConnectModal();
              }}
            >
              <span>Connect Wallet</span>
              <img
                src={require('assets/Homepage/wallet_image.png').default}
                alt=""
              />
            </div>
          )}
          <div
            className="menu"
            onClick={() => {
              onMoveAnimation('menu-modal-ov', 'moveInOpacity');
            }}
          >
            <span>Menu</span>
            <MenuIcon className="ic" />
          </div>
        </div>
        <div className="account-detail" id="account-detail-ov"></div>
      </Container>
    </Wrapper>
  );
};

export const Header = withWallet(HeaderComponent);

export default Header;
