import styled from 'styled-components';

export const Wrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: 'Hepta Slab';
  .form {
    background: #19191a;
    border-radius: 24px;
    width: 50%;
    ${(props) => props.theme.mediaWidth.upToMedium`
    width: 95%;`}
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
      padding: 20px 10px;
    `}
    ${(props) => props.theme.mediaWidth.upToExtraSmall2`
      height:75%;
    `}
    padding: 40px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .header {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      .title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        font-size: 24px;
            `}
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        font-size: 16px;
            `}
      }
      .icon {
        color: white;
      }
    }
    .connectors {
      margin-top: 50px;
      ${(props) => props.theme.mediaWidth.upToExtraSmall2`
      margin-top: 10px;
            `}
      .connector {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(:first-child) {
          margin-top: 35px;
        }
        .wallet-image {
          width: 80px;
          ${(props) => props.theme.mediaWidth.upToExtraSmall`
          width: 40px;
          `}
          ${(props) => props.theme.mediaWidth.upToExtraSmall`
          width: 80px;
          `}
        }
        .connect {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-left: 24px;
          width: 100%;
          ${(props) => props.theme.mediaWidth.upToExtraSmall`

          margin-left: 12px;
            `}
          ${(props) => props.theme.mediaWidth.upToExtraSmall2`
          flex-direction: column;
          align-items: flex-start;
          row-gap: 12px;

            `}
          .description {
            display: flex;
            flex-direction: column;
            .title {
              font-weight: 600;
              font-size: 16px;
              ${(props) => props.theme.mediaWidth.upToExtraSmall`
              font-size: 14px;
            `}
            }
            .subtitle {
              color: #979ba5;
              font-size: 16px;
              margin-top: 7px;
              ${(props) => props.theme.mediaWidth.upToExtraSmall`
              font-size: 12px;
            `}
            }
          }
          .button {
            background: #35363d;
            border: 1px solid #57595e;
            border-radius: 28px;
            color: #ff42ca;
            font-size: 16px;
            text-transform: capitalize;
            &:disabled {
              color: transparent;
            }
            ${(props) => props.theme.mediaWidth.upToExtraSmall`
              font-size: 12px;
            `}
          }
        }
      }
    }
  }
`;
