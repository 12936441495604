import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: 'Hepta Slab';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  width: 100%;
  overflow-x: hidden;
  color: white;
  z-index: 101;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgb(96, 96, 96);
  overflow: hidden;
  backdrop-filter: blur(50px);
  transition: all 0.3s;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 105px;
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
        height: 65px;
      `}
    .menu {
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      border: 1px solid #666666;
      border-radius: 2px;
      display: none;
      ${(props) => props.theme.mediaWidth.upToExtraSmall`
         display: flex;
      `}
      ${(props) => props.theme.mediaWidth.upToExtraSmall1`
      padding: 4px;
      span{
        display:none;
      }
      `}

      span {
        font-family: 'Hepta Slab';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #ffffff;
      }
    }
    .left-header {
      display: flex;
      align-items: center;
      gap: 40px;
      .logo {
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
        width: 100px;
      `}
      }
      .nav-lists {
        display: flex;
        align-items: center;
        gap: 24px;
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
         display: none;
      `}
        .nav-nav {
          cursor: pointer;
          font-family: 'Hepta Slab';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          /* identical to box height, or 26px */

          color: #ffffff;
          transition: all 0.3s;
          // padding: 8px 22px;
          // border-radius: 8px;
          &:hover {
          }
          &:active {
            transform: scale(0.85);
            opacity: 0.5;
          }
        }
      }
    }
    .btn-launch-gt {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 20px 24px;
      border: 1px solid #ffffff;
      border-radius: 2px;
      cursor: pointer;
      ${(props) => props.theme.mediaWidth.upToExtraSmall`
         display: none;
      `}
      img {
        width: 24px;
      }
      span {
        font-family: 'Hepta Slab';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #ffffff;
      }
    }
    .logo {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .account-info {
      cursor: pointer;
      background-color: rgb(42, 42, 45);
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      padding: 8px 22px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      gap: 12px;
      .left {
        display: flex;
        gap: 8px;
        align-items: center;
        img {
          width: 20px;
        }
      }
      .account-address {
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
