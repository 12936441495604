import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/term-of-service';

const LiquidityRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../TermOfService')),
  name: 'TermOfService',
};

export default LiquidityRoute;
