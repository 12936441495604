import React, { memo } from 'react';
import { Wrapper } from './Loading.styled';

const LoadingComponent = () => {
  return (
    <Wrapper id="loading">
      <img
        className="logo"
        src={require('assets/logo/galoswap logo-02.svg').default}
        alt=""
      />
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);

export default Loading;
