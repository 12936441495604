export {};
// export const route = '/swap';

// const SwapRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('../Swap')),
//   name: 'Swap',
// };

// export default SwapRoute;
