import { IconButton } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { Wrapper } from './ConnectForm.styled';
import { WALLET } from 'constants/wallet';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import withWallet from 'hoc/withWallet';

const ConnectFormComponent = ({
  connect,
  onCloseConnectModal,
  account,
}: any) => {
  const [loading, setLoading] = useState(-1);
  useEffect(() => {
    account && onCloseConnectModal();
  }, [account]);
  return (
    <Wrapper id="connect-wallet-overlay">
      <div className="overlay" onClick={onCloseConnectModal} />
      <div className="form">
        <div className="header">
          <span className="title">Connect Your Wallet</span>
          <IconButton onClick={onCloseConnectModal} className="icon">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="connectors">
          {WALLET.map((wallet, index) => (
            <div key={index} className="connector">
              <img
                className="wallet-image"
                src={require(`../../assets/wallet/${wallet.path}`).default}
                alt={wallet.name}
              />
              <div className="connect">
                <div className="description">
                  <span className="title">{wallet.name}</span>
                  <span className="subtitle">{wallet.description}</span>
                </div>
                <LoadingButton
                  loading={loading === index}
                  onClick={async () => {
                    setLoading(index);
                    await connect(wallet.connector);
                    setLoading(-1);
                  }}
                  variant="outlined"
                  className="button"
                >
                  Connect
                </LoadingButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export const ConnectForm = memo(withWallet(ConnectFormComponent));
export default ConnectForm;
