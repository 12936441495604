import React from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './Footer.styled';

export const Footer = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <div className="tg-container">
        <div className="footer">
          <div className="part part__1">
            <div className="logo">
              <img
                src={require('assets/logo/galoswap logo-02.svg').default}
                alt=""
              />
            </div>
            <span className="content">
              The first innovative DEX & NFT marketplace built to enhance
              liquidity using ZK-Rollups for optimal security and scalability.
            </span>
            <div className="info-media">
              <img
                onClick={() => {
                  window.open('https://twitter.com/GaloSwap', 'blank');
                }}
                src={require('assets/Homepage/twitter.png').default}
                alt=""
              />
              <img
                onClick={() => {
                  window.open(
                    'https://mirror.xyz/0x11AE2441aEc01D3E177eF532A5Dc2Fb975fe2f8B',
                    'blank',
                  );
                }}
                src={require('assets/Homepage/medium-m.png').default}
                alt=""
              />
              <img
                onClick={() => {
                  window.open('https://t.me/GaloSwap', 'blank');
                }}
                src={require('assets/Homepage/telegram-plane.png').default}
                alt=""
              />
              <img
                onClick={() => {
                  window.open('https://discord.gg/r2xaFd2f2W', 'blank');
                }}
                src={require('assets/Homepage/Discord.png').default}
                alt=""
              />
            </div>
          </div>
          <div className="part part__2">
            <div className="mini-part">
              <div className="mini-title">Resources</div>
              <div className="mini-list">
                <div
                  className="mini-item"
                  onClick={() => {
                    window.open(
                      'https://galoswap.gitbook.io/galoswap/',
                      'blank',
                    );
                  }}
                >
                  Documentation
                </div>
                <div className="mini-item">GitHub</div>
                <div
                  className="mini-item"
                  onClick={() => {
                    window.open(
                      'https://mirror.xyz/0x11AE2441aEc01D3E177eF532A5Dc2Fb975fe2f8B/subscribe',
                      'blank',
                    );
                  }}
                >
                  Blog{' '}
                </div>
              </div>
            </div>
            <div className="mini-part">
              <div className="mini-title">GALO</div>
              <div className="mini-list">
                <div className="mini-item">Token contract</div>
                <div className="mini-item">Buy GALO</div>
                <div className="mini-item">View Coingecko</div>
                <div className="mini-item">View Coinmarketcap</div>
              </div>
            </div>
            <div className="mini-part">
              <div className="mini-title">Company</div>

              <div className="mini-list">
                <div
                  className="mini-item"
                  onClick={() => {
                    history.push('/term-of-service');
                  }}
                >
                  Term of service
                </div>
                <div className="mini-item">Brand kit</div>
                <div className="mini-item">FAQ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
