import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/';

const HomepageRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../Homepage')),
  name: 'Homepage',
};

export default HomepageRoute;
