import { Button, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { accounts } from 'constants/white-list';
import React, { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Wrapper } from './SignIn.styled';

interface ISignInDataInput {
  userName: string;
  password: string;
}

export const SignIn = ({ setAuth }: any) => {
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<ISignInDataInput>({ mode: 'onChange' });
  const onSignIn = useCallback(async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const values = getValues();
    if (
      values.userName === accounts.userName &&
      values.password === accounts.password
    ) {
      setAuth(values.password);
    } else {
      toast.error('Invalid email or password');
    }
  }, []);
  return (
    <Wrapper>
      <Stack
        direction="column"
        sx={{
          width: '400px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          padding: '20px',
          borderRadius: '10px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <img
          style={{ width: '200px' }}
          src={require('assets/logo/galoswap logo-02.png').default}
        />
        <TextField
          {...register('userName', { required: 'Username cannot be empty' })}
          fullWidth
          sx={{ marginTop: '20px' }}
          label="Username"
          error={!!errors?.userName}
          helperText={errors.userName?.message}
        />
        <TextField
          {...register('password', { required: 'Password cannot be empty' })}
          fullWidth
          sx={{ marginTop: '20px' }}
          label="Password"
          type="password"
          error={!!errors?.password}
          helperText={errors.password?.message}
        />
        <Button
          onClick={onSignIn}
          sx={{ marginTop: '20px' }}
          variant="contained"
        >
          Sign In
        </Button>
      </Stack>
    </Wrapper>
  );
};

export default memo(SignIn);
