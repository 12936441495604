export {};
// export const route = '/launchpad/name';

// const LiquidityRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('../LaunchPadDetail')),
//   name: 'LaunchPadDetail',
// };

// export default LiquidityRoute;
