import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IRouteProps } from 'modules';
import enhance from 'modules/MainRoute/MainRoute.enhance';
import { IProps } from 'modules/MainRoute/MainRoute.inteface';
import { SignIn } from 'modules/SignIn';
import { useStoreAuthenticate } from 'services/hooks/useAuthenticate';
import { accounts } from 'constants/white-list';

const MainRoute = (props: IProps & any) => {
  const { routes } = props;
  const [auth, setAuth] = useStoreAuthenticate();

  if (!routes || routes.length === 0) return null;
  const isAuthenticated = process.env.REACT_APP_ISTESTING
    ? auth === accounts.password
    : true;
  if (!isAuthenticated) {
    return <SignIn setAuth={setAuth} />;
  }

  return (
    <Switch>
      {routes.map((route: IRouteProps) => {
        if (route) {
          const Component = lazy(() => import(`../${route.name}`));
          return (
            <Route {...route} key={route.path}>
              <Suspense fallback={null}>
                <Component />
              </Suspense>
            </Route>
          );
        }
        return null;
      })}
    </Switch>
  );
};

export default enhance(React.memo(MainRoute));
